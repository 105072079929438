import React, {useEffect} from 'react';
import {isMobile} from "react-device-detect";
import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil";

const AdHeader = () => {
    useEffect(() => {
        window.googletag = window.googletag || {cmd: []};
        const timeout = setTimeout(() => {
            if (window.googletag) {
                googletag.cmd.push(function () {
                    try {
                        googletag.display('div-pt_leaderboard_atf_mobile');
                        googletag.display('div-pt_leaderboard_atf_desktop');
                    } catch (e) {

                    }
                });
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return <div className="ad-container" data-datocms-noindex>
        <div className="advertisement-text">Advertisement</div>
        <div id="div-pt_leaderboard_atf_mobile"></div>
        <div id="div-pt_leaderboard_atf_desktop"></div>
    </div>
}

export default AdHeader;
