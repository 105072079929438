const STRINGS = {
    HOME: "home",
    LATEST_NEWS: "Latest News",
    SUBSCRIBE: "Join Our <span style='font-weight: 700;'>FREE</span> Mailing List",
    SUBSCRIBE_NOW: "<span style='font-weight: 700;'>FREE</span> Newsletter",
    ADDRESS: "Address: Lower Ground Floor,<br/>One George yard, London,<br/>EC3V 9DF, UK."
}

Object.freeze(STRINGS);

export {STRINGS}